import { graphql, useStaticQuery } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import { Helmet } from "react-helmet";
import CtaBanner from "../components/cta-banner";
import HeroBanner from "../components/hero";
import Layout from "../components/layout";
import TwoColumnImageContent from "../components/two-column-image-content";

const NCZCommunityPage = () => {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					siteUrl
				}
			}
			pageData: wpPage(slug: { eq: "ncz-community" }) {
				seoFields {
					localBusinessSchema
					metaDescription
					opengraphDescription
					opengraphTitle
					title
					image {
						node {
							altText
							sourceUrl
							mediaDetails {
								height
								width
							}
						}
					}
				}
				nczCommunity {
					nczBannerSection {
						bannerSectionHeading
						bannerSectionDescription
						bannerSectionBackgroundImage {
							node {
								altText
								sourceUrl
							}
						}
					}
					nczTwoColumnEvents {
						twoColumnEventsHeading
						twoColumnEventsSubHeading
						eventsImageBackgroundColor
						twoColumnEventsContent
						twoColumnEventsButton1 {
							title
							target
							url
						}
						twoColumnEventsButton2 {
							title
							target
							url
						}
						twoColumnEventsImage {
							node {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: WEBP
											quality: 80
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					nczTwoColumnContent {
						twoColumnContentHeading
						twoColumnContentSubHeading
						twoColumnContentContent
						backgroundColor
						twoColumnContentButton1 {
							title
							target
							url
						}
						twoColumnContentButton2 {
							title
							target
							url
						}
						twoColumnContentImage {
							node {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: WEBP
											quality: 80
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					nczTwoColumnPortal {
						twoColumnPortalHeading
						twoColumnPortalSubHeading
						twoColumnPortalContent
						twoColumnPortalButton1 {
							title
							target
							url
						}
						twoColumnPortalButton2 {
							title
							target
							url
						}
						twoColumnPortalImage {
							node {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: WEBP
											quality: 80
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					nczCommunityCta {
						nczCommunityCtaHeading
						nczCommunityCtaDesctiption
						nczCommunityCtaButton1 {
							title
							url
							target
						}
						nczCommunityCtaButton2 {
							title
							url
							target
						}
						nczCommunityCtaBgImage {
							node {
								altText
								sourceUrl
							}
						}
					}
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const {
		pageData: { seoFields, nczCommunity },
	} = data;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "NCZ Community",
				item: {
					url: `${siteUrl}/ncz-community`,
					id: `${siteUrl}/ncz-community`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/ncz-community`,
					title: `${seoFields?.opengraphTitle}`,
					description: `${seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${seoFields?.image?.node.sourceUrl}`,
							width: `${seoFields?.image?.node.mediaDetails.width}`,
							height: `${seoFields?.image?.node.mediaDetails.height}`,
							alt: `${seoFields?.image?.node.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<HeroBanner
					heading={nczCommunity?.nczBannerSection.bannerSectionHeading}
					content={nczCommunity?.nczBannerSection.bannerSectionDescription}
					bgImage={
						nczCommunity?.nczBannerSection.bannerSectionBackgroundImage?.node
							?.sourceUrl
					}
					showUnderline={false}
					textAlign={"center"}
					columns={8}
				/>
				{/* <TwoColumnImageContent
					heading={nczCommunity?.nczTwoColumnEvents.twoColumnEventsHeading}
					content={nczCommunity?.nczTwoColumnEvents.twoColumnEventsContent}
					tagline={nczCommunity?.nczTwoColumnEvents.twoColumnEventsSubHeading}
					btnCta1={nczCommunity?.nczTwoColumnEvents.twoColumnEventsButton1}
					image={nczCommunity?.nczTwoColumnEvents.twoColumnEventsImage.node}
					bgColor={
						nczCommunity?.nczTwoColumnEvents.twoColumnImageBackgroundColor
					}
					textColor={"#000"}
				/> */}
				<TwoColumnImageContent
					heading={nczCommunity?.nczTwoColumnContent.twoColumnContentHeading}
					content={nczCommunity?.nczTwoColumnContent.twoColumnContentContent}
					tagline={nczCommunity?.nczTwoColumnContent.twoColumnContentSubHeading}
					btnCta1={nczCommunity?.nczTwoColumnContent.twoColumnContentButton1}
					btnCta2={nczCommunity?.nczTwoColumnContent.twoColumnContentButton2}
					image={nczCommunity?.nczTwoColumnContent.twoColumnContentImage?.node}
					mainBgColor={nczCommunity?.nczTwoColumnContent.backgroundColor}
					textColor={"#fff"}
					bodyTextColor={"#fff"}
					borderColor={"#fff"}
					flip={true}
				/>
				<TwoColumnImageContent
					heading={nczCommunity?.nczTwoColumnPortal.twoColumnPortalHeading}
					content={nczCommunity?.nczTwoColumnPortal.twoColumnPortalContent}
					tagline={nczCommunity?.nczTwoColumnPortal.twoColumnPortalSubHeading}
					btnCta1={nczCommunity?.nczTwoColumnPortal.twoColumnPortalButton1}
					btnCta2={nczCommunity?.nczTwoColumnPortal.twoColumnPortalButton2}
					image={nczCommunity?.nczTwoColumnPortal.twoColumnPortalImage?.node}
					bgColor={
						nczCommunity?.nczTwoColumnPortal.twoColumnImageBackgroundColor
					}
					textColor={"#000"}
				/>
				<CtaBanner
					heading={nczCommunity?.nczCommunityCta.nczCommunityCtaHeading}
					content={nczCommunity?.nczCommunityCta.nczCommunityCtaDesctiption}
					btnCta1={nczCommunity?.nczCommunityCta.nczCommunityCtaButton1}
					btnCta2={nczCommunity?.nczCommunityCta.nczCommunityCtaButton2}
					bgImage={nczCommunity?.nczCommunityCta.nczCommunityCtaBgImage?.node}
					textPosition="start"
					isGreen={true}
				/>
			</Layout>
		</>
	);
};

export default NCZCommunityPage;
